import React, { useEffect, useState } from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Leaf } from "@cdlvsm/website.backend.lib";

import { Box, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography, MenuItem, Select } from "@mui/material";

import { Blog } from "@cdlvsm/website.backend.lib";
import PageIcon from "@mui/icons-material/Description";
import { LeafComponent } from "./leaf";
import { useContext } from '../../utils/use-context';
import { getPathParts } from "../../../utils/path";
import { AddTreePartModal } from './utils/AddTreePartModal';
import { RootState } from '../../../state';

export interface BlogComponentProps {
    blog: Blog;
    currentPath: string;
    loggedIn: boolean;
    state: RootState["ui"];
}

export const BlogComponent = ({ blog, currentPath, loggedIn, state }: BlogComponentProps) => {
    const getSelectedLeaf = () => {
        const paths = getPathParts(currentPath);
        return blog.parts.find(part => paths.includes(part.path)) || blog.parts[0] || null;
    };

    const selectedLeaf: Leaf | null = getSelectedLeaf();
    const chromosome = useContext();

    const onPageSelect = (path: string) => {
        chromosome.onPathSelect(path);
    };

    const [addTreePartModalOpen, setAddTreePartModalOpen] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [selectedPath, setSelectedPath] = useState(selectedLeaf?.path || "");

    const onAddClick = () => {
        setAddTreePartModalOpen(true);
    };

    const onTreePartAdd = (title: string, path: string) => {
        chromosome.onTreePartAdd(blog.path, title, path, "Leaf");
    };

    const handleResize = () => {
        setIsDropdown(window.innerWidth <= 900);
    };

    const handleDropdownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const path = event.target.value as string;
        setSelectedPath(path);
        onPageSelect(path);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize(); // Initial check
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (selectedLeaf) {
            setSelectedPath(selectedLeaf.path);
        }
    }, [selectedLeaf]);

    return (
        <>
            {isDropdown ? (
                <Grid container>
                    <Grid item xs={12} md={12}>
                        {/* <Select
                            value={selectedPath || (blog.parts[0]?.path || "")}
                            onChange={handleDropdownChange as any}
                            fullWidth
                        >
                            {Object.values(blog.parts).map((leaf, index) => (
                                <MenuItem key={index} value={leaf.path}>
                                    {leaf.title}
                                </MenuItem>
                            ))}
                        </Select> */}
                        <List dense={true}>
                            {Object.values(blog.parts).map((leaf, index) => (
                                <ListItemButton
                                    key={index}
                                    selected={leaf.path === selectedLeaf?.path}
                                    onClick={() => onPageSelect(leaf.path)}
                                >
                                    <ListItemIcon>
                                        <PageIcon fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            sx: {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }
                                        }}
                                        primary={leaf.title}
                                        secondary={new Date(leaf._createdAt).toLocaleDateString()}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {selectedLeaf && <LeafComponent leaf={selectedLeaf} loggedIn={loggedIn} state={state} />}
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid item xs={12} md={10}>
                        {selectedLeaf && <LeafComponent leaf={selectedLeaf} loggedIn={loggedIn} state={state} />}
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <List dense={true}>
                            {Object.values(blog.parts).map((leaf, index) => (
                                <ListItemButton
                                    key={index}
                                    selected={leaf.path === selectedLeaf?.path}
                                    onClick={() => onPageSelect(leaf.path)}
                                >
                                    <ListItemIcon>
                                        <PageIcon fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            sx: {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }
                                        }}
                                        primary={leaf.title}
                                        secondary={new Date(leaf._createdAt).toLocaleDateString()}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            )}
            {loggedIn && (
                <Box
                    sx={{
                        '& > :not(style)': { m: 1 },
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        float: 'right',
                        color: 'gray'
                    }}
                >
                    <Typography>Add a new blog page</Typography>
                    <Fab color="primary" aria-label="add" onClick={onAddClick}>
                        <AddIcon />
                    </Fab>
                    <AddTreePartModal
                        open={addTreePartModalOpen}
                        onClose={() => setAddTreePartModalOpen(false)}
                        onTreePartAdd={onTreePartAdd}
                        types={["Leaf"]}
                    />
                </Box>
            )}
        </>
    );
};
