import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import { useEffect, useState } from "react";
import { Field, Leaf } from "@cdlvsm/website.backend.lib";
import { Box, Button, Divider, MenuItem, Select, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useContext } from '../../utils/use-context';
import { TinyMce } from "./TinyMce";
import { DeleteButton } from "./DeleteButton";
import { RootState } from '../../../state';
import { HistoryBar } from './utils/HistoryBar';
import { FormBuilder } from "./FormBuilder";
import { useTheme } from "@mui/material/styles";

const actionBoxstyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingX: "1em",
  color: 'gray'
};

const cardStyle = (isEditMode: boolean, theme: any) => ({
  minWidth: 275,
  boxShadow: 10,
  position: 'relative',
  border: isEditMode ? `2px solid ${theme.palette.warning.main}` : "inherit",
  marginBottom: "1em"
});

export interface LeafComponentProps {
  leaf: Leaf;
  state: RootState["ui"];
  loggedIn: boolean;
}

export interface PageProps {
  leaf: Leaf;
  state: RootState["ui"];
  loggedIn: boolean;
  content: string;
  setContent: (content: string) => void;
  chromosome: ReturnType<typeof useContext>;
  isEditMode: boolean;
  setMode: (mode: "View" | "Edit") => void;
}

export type FormProps = Omit<PageProps, "content" | "setContent"> & {
  content: Field[];
  setContent: (content: Field[]) => void;
}


export const LeafComponent = ({ leaf, loggedIn, state }: LeafComponentProps) => {
  const theme = useTheme();
  const chromosome = useContext();
  const path = leaf.path;
  const { page, pageHistory, pages } = state;
  const [content, setContent] = useState<{ html: string; fields?: Field[] }>({ html: "", fields: undefined });
  const [mode, setMode] = useState<"View" | "Edit">("View");
  const isEditMode = mode === "Edit";

  useEffect(() => {
    if (leaf.pageSequenceId) {
      if (!page || page.sequenceId !== leaf.pageSequenceId || (loggedIn && (!pageHistory || !pageHistory.length || pageHistory[0].sequenceId !== leaf.pageSequenceId))) {
        setContent({ html: "", fields: undefined });
        chromosome.onPageLoad(leaf.pageSequenceId);
      } else {
        setContent({ html: page.html, fields: page.fields });
      }
    } else {
      setContent({ html: "This page is not linked to any page sequence", fields: undefined });
    }
  }, [page, pages, pageHistory, leaf.pageSequenceId]);

  useEffect(() => {
    if (!window.location.pathname.endsWith(path)) {
      chromosome.onPathSelect(path);
    }
  }, [window.location.pathname, path]);

  const onDelete = () => {
    chromosome.onTreePartDelete(leaf);
  }
  const onFormDelete = () => {
    setContent({ html: content.html, fields: undefined });
  }
  const onEditClick = () => {
    setMode("Edit");
  }
  const onViewClick = () => {
    setMode("View");
    chromosome.onPageContentChange(leaf, leaf.title, content);
  }
  const onAddFormClick = () => {
    setContent({ html: content.html, fields: [] });
  }
  const onPageVersionSelect = (pageId: string) => {
    chromosome.onPageVersionSelect(pageId);
  }
  const onVersionDelete = () => {
    chromosome.onPageVersionDelete(page!.id);
  }

  const onFieldsChange = (updatedFields: Field[]) => {
    setContent({ ...content, fields: updatedFields });
  };

  return (
    <div
      role="tabpanel"
    >
      {loggedIn && page && pageHistory && <HistoryBar items={pageHistory} onItemSelect={onPageVersionSelect} selectedItem={page.id} />}
      {loggedIn && <Card sx={cardStyle(isEditMode, theme)} variant="outlined">
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: "1em"
          }}
        >
          <Box sx={actionBoxstyle}>
            <Typography component="div">Remove this page</Typography>
            <DeleteButton onDelete={onDelete} />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={actionBoxstyle}>
            <Typography component="div">Remove this page version</Typography>
            <DeleteButton onDelete={onVersionDelete} />
          </Box>
          <Divider orientation="vertical" flexItem />
          {
            isEditMode ?
              <Box sx={actionBoxstyle}>
                <Typography component="div" style={{ marginRight: "1em" }}>Save this page</Typography>
                <Fab color="primary" aria-label="save" onClick={onViewClick}>
                  <SaveIcon />
                </Fab>
              </Box> :
              <Box sx={actionBoxstyle}>
                <Typography component="div" style={{ marginRight: "1em" }}>Edit and create latest page from this version</Typography>
                <Fab color="primary" aria-label="edit" onClick={onEditClick}>
                  <EditIcon />
                </Fab>
              </Box>
          }
        </CardActions>
      </Card>}
      <Card sx={cardStyle(isEditMode, theme)} variant="outlined">
        <CardContent>
          <Typography variant="body2" component={"span"}>
            {!isEditMode && <>
              <style>
                {`
                    .rendered-content img {
                      max-width: 100%;
                      height: auto;
                      display: block;
                    }
                  `}
              </style>
              <div className="rendered-content" dangerouslySetInnerHTML={{ __html: content.html }} />
            </>}
            {isEditMode && <TinyMce value={content.html} onChange={(value) => setContent({ ...content, html: value })} />}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom component={"span"}>
            { }
          </Typography>
        </CardContent>
      </Card>
      {
        (content.fields !== undefined) &&
        <Card sx={cardStyle(isEditMode, theme)} variant="outlined">
          <CardContent>
            <FormBuilder isEditMode={isEditMode} fields={content.fields} onSubmit={(formData) => chromosome.onFormSubmit(formData)} onFieldsChange={onFieldsChange} loggedIn={loggedIn} />
          </CardContent>
        </Card>
      }
      {
        isEditMode && (
          content.fields === undefined &&
          <Box sx={{
            ...actionBoxstyle,
            marginTop: "1em",
          }}>
            <Typography component="div" sx={{ marginRight: "1em" }}>Add a input form</Typography>
            <Fab color="primary" aria-label="add" onClick={onAddFormClick}>
              <DynamicFormIcon />
            </Fab>
          </Box> ||
          <Box sx={{
            ...actionBoxstyle,
            marginTop: "1em",
          }}>
            <Typography component="div" sx={{ marginRight: "1em" }}>Remove the form</Typography>
            <DeleteButton onDelete={onFormDelete} />
          </Box>
        )
      }
    </div>
  )
};