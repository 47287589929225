import { logger } from "@euglena/compact";
import { HttpClientFetch } from "@euglena/compact.fetch";
import { ui } from "./chromosome";
import { LocalStorage } from "./organelle/localstorage";
// import { ProgressClient as BackendClient } from "./organelles/progress-client";
import { Memory } from "./organelle/vacuole-js";
import particles from "./particles";
import { auth } from "@euglena/compact.cdlvsm";
import { Navigator } from "@euglena/compact.navigator";
import { Instincts } from "./chromosome/instinct.chr";
import { UIOrganelle } from "./organelle/ui";
import { Backend } from "./organelle/backend";

export let props: { euglena: Euglena } = { euglena: undefined } as any;
const uiChromosome = new ui.UIChromosome(props!);
const instincts = new Instincts(props!);

const AUTH_URL: string = process.env.REACT_APP_AUTH_URL as string;
const BACKEND_URL: string = process.env.REACT_APP_BACKEND_URL as string;
const AUTH_PASS: string = process.env.REACT_APP_BACKEND_URL as string;

export class Euglena {
    ui = new UIOrganelle(uiChromosome);
    logger = new logger.Logger();
    vacuoleJs = new Memory(particles, instincts);
    localStorage = new LocalStorage(instincts);
    authClient = new auth.AuthClient(new HttpClientFetch("auth", AUTH_URL, instincts));
    backend = new Backend(new HttpClientFetch("backend", BACKEND_URL, instincts), window.location.hostname);
    navigator = new Navigator(uiChromosome);
}

const euglena = new Euglena();
props.euglena = euglena;
instincts.preRender();