import { useState } from "react";
import {
  Fab,
  Modal,
  Card,
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import QueryStatsIcon from '@mui/icons-material/MarkUnreadChatAlt';
import { EnhancedFormData } from ".";

const formatDate = (dateNumber: number) => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(dateNumber).toLocaleDateString(undefined, options);
};

export const FormDataViewer = ({ formDatas = [] }: { formDatas?: EnhancedFormData[]; }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [previewData, setPreviewData] = useState<Record<string, string> | null>(null);

  const handlePreviewClick = (data: Record<string, string>) => {
    setPreviewData(data);
  };

  const handleClosePreview = () => {
    setPreviewData(null);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 190,
        left: 0,
        margin: 10,
        padding: 10,
        borderRadius: 10,
      }}
    >
      <Fab onClick={() => setModalOpen(true)}>
        <QueryStatsIcon fontSize="large" color="primary" />
      </Fab>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Card
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="h2">
                Table here to show form data
              </Typography>
            }
          />
          <CardContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Page Title</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formDatas.map((data) => (
                      <TableRow key={data.id}>
                        <TableCell>{data.id}</TableCell>
                        <TableCell>{data.pageTitle}</TableCell>
                        <TableCell>{formatDate(data._createdAt)}</TableCell>
                        <TableCell>
                          <Button onClick={() => handlePreviewClick(data.data)}>Preview</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </CardActions>
        </Card>
      </Modal>
      {previewData && (
        <Modal open={!!previewData} onClose={handleClosePreview}>
          <Card
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" component="h2">
                  Form Data Details
                </Typography>
              }
            />
            <CardContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {Object.entries(previewData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleClosePreview}>Close</Button>
            </CardActions>
          </Card>
        </Modal>
      )}
    </div>
  );
};
