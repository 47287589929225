import { useState } from "react";
import {
  Fab,
  Modal,
  Card,
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Button,
  Typography,
} from "@mui/material";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export const Statistics = ({
}: {

}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 120,
        left: 0,
        margin: 10,
        padding: 10,
        borderRadius: 10,
      }}
    >
      <Fab onClick={() => setModalOpen(true)}>
        <QueryStatsIcon fontSize="large" color="primary" />
      </Fab>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Card
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="h2">
                Statistics
              </Typography>
            }
          />
          <CardContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="body1" component="p">
                Some statistics here
              </Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </CardActions>
        </Card>
      </Modal>
    </div>
  );
};
