import { State } from "@euglena/compact";
import { Page, PageHistory, PageTree, PageTreeHistory, WebsiteFormData } from "@cdlvsm/website.backend.lib";
import { auth } from "@euglena/compact.cdlvsm";

export interface RootState extends State {
    user?: auth.User;
    domain: string;
    ui: {
        notification?: Notification;
        title: string;
        pageTree?: PageTree;
        pageTreeHistory?: PageTreeHistory | null;
        pageHistory?: PageHistory | null;
        page?: Page | null;
        pages: { [id: string]: Page | null };
        path: string;
        formDatas?: WebsiteFormData[];
    }
}

export const getLatestPageBySequenceId = (pages: { [id: string]: Page | null }, sequenceId: string) => {
    const pagesInSequence = Object.values(pages).filter(page => page?.sequenceId === sequenceId) as Page[];
    if (pagesInSequence.length <= 0) return undefined;
    return pagesInSequence.sort((a, b) => b?._createdAt - a?._createdAt)[0];
}

export class Notification {
    constructor(public message: string, public severity: "success" | "info" | "warning" | "error") { }
}

export const initialState: RootState = {
    _class: "State",
    _createdAt: new Date().getTime(),
    domain: window.location.hostname,
    ui: {
        title: "",
        path: window.location.pathname,
        pages: {},
    }
}