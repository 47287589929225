import { Exception, Log } from "@euglena/compact";
import { Chromosome } from "./chromosome";


export class Instincts extends Chromosome {

  log(message: string, level: Log["level"]) {
    return this.handleEvent(async (state) => {
      this.props.euglena.logger.log(message, level);
      return state;
    });
  }

  async preRender() {
    return this.handleEvent(async (state) => {
      const organelleNames = Object.keys(this.props.euglena);
      const { logger, ui, vacuoleJs, localStorage, authClient, } = this.props.euglena;
      logger.info(`Organelle names: ${organelleNames.join(",")}`);
      const token = await localStorage.getToken();
      if (token) {
        //user
        const user = await authClient.getUser(token.encryptedToken);
        if (Exception.isException(user)) {
          logger.error(user.message); return state;
        }
        state = { ...state, user };
      }
      // update path from window.location.pathname
      const path = window.location.pathname;
      if (path === "/login" && state.user) {
        this.props.euglena.navigator.navigateTo("/", true);
      } else {
        if (state.user) {
          const token = await localStorage.getToken();
          const pageTreeWithHistory = await this.props.euglena.backend.getPageTreeWithHistory(state.domain, token?.encryptedToken!);
          if (Exception.isException(pageTreeWithHistory)) {
            logger.error(pageTreeWithHistory.message);
            return { ...state, ui: { ...state.ui, notification: { message: pageTreeWithHistory.message, severity: "error" } } };
          }
          const formDatas = await this.props.euglena.backend.listFormData(state.domain, { offset: 0, limit: 10, orderBy: "_createdAt", order: "desc" }, token?.encryptedToken!);
          if (Exception.isException(formDatas)) {
            logger.error(formDatas.message);
            return { ...state, ui: { ...state.ui, notification: { message: formDatas.message, severity: "error" } } };
          }
          state = { ...state, ui: { ...state.ui, pageTree: pageTreeWithHistory.pageTree, pageTreeHistory: pageTreeWithHistory.pageTreeHistory, formDatas } };
        } else {
          const pageTree = await this.props.euglena.backend.getLatestPageTreeByDomain(state.domain);
          if (Exception.isException(pageTree)) {
            logger.error(pageTree.message);
            return { ...state, ui: { ...state.ui, notification: { message: pageTree.message, severity: "error" } } };
          }
          state = { ...state, ui: { ...state.ui, pageTree } };
        }
      }
      state = { ...state, ui: { ...state.ui, path } };
      // save state
      vacuoleJs.saveState(state);
      ui?.render(state);
      return state;
    });
  }

  onUnauthenticated(serverEuglenaName: string) {
    return this.handleEvent(async (state) => {
      switch (serverEuglenaName) {
        case "auth":
        case "progress":
          // this.handleEvent(new SignoutButtonClicked());
          break;
        default:
          this.log(`onUnauthenticated: Unknown server name: ${serverEuglenaName}`, "Error");
          break;
      }
      return state;
    });
  }
}