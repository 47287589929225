import { Exception, Log, Particle } from "../../particle";

export interface Nucleus {
    log(message: string, level: Log["level"]): void;
}

export interface ListOptions {
    offset?: number;
    limit?: number;
    order?: "asc" | "desc";
    orderBy?: string;
}

export abstract class Vacuole {
    constructor(protected nucleus: Nucleus) { }
    abstract getAlive(): Promise<void | Exception>;
    abstract read<T extends Particle>(query: Partial<T>, count?: number | "All"): Promise<T[] | Exception>;
    abstract list<T extends Particle>(query: Partial<T>, options?: ListOptions): Promise<T[] | Exception>;
    abstract save<T extends Particle>(particle: T, query?: Partial<T>, count?: number | "All"): Promise<void | Exception>;
    abstract saveAll<T extends Particle>(particles: T[]): Promise<void | Exception>;
    abstract remove<T extends Particle>(query: Partial<T>, count?: number | "All"): Promise<void | Exception>;
}