import React, { useState } from "react";
import {
    TextField, Button, Box, FormControl, FormControlLabel, RadioGroup, Radio, Checkbox, Select, MenuItem, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Snackbar, Fab,
} from "@mui/material";
import { Field, Option } from "@cdlvsm/website.backend.lib";
import { Delete, Edit, Add } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useTheme } from "@mui/material/styles";

export interface FormBuilderProps {
    fields: Field[];
    onSubmit: (data: Record<string, any>) => void;
    isEditMode: boolean;
    onFieldsChange?: (fields: Field[]) => void; // Add optional callback for field changes
    loggedIn: boolean; // Add loggedIn prop
}

const getItemStyle = (isDragging: boolean, draggableStyle: any, theme: any) => ({
    userSelect: "none",
    padding: "16px",
    margin: "0 0 8px 0",
    background: isDragging ? theme.palette.action.hover : theme.palette.background.paper,
    borderRadius: "4px",
    boxShadow: isDragging ? "0 2px 8px rgba(0, 0, 0, 0.2)" : "none",
    ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean, theme: any) => ({
    padding: "8px",
    background: isDraggingOver ? theme.palette.action.selected : theme.palette.background.paper,
    borderRadius: "4px",
    width: "100%",
});

export const FormBuilder: React.FC<FormBuilderProps> = ({ fields, onSubmit, isEditMode, onFieldsChange, loggedIn }) => {
    const theme = useTheme();
    const [formData, setFormData] = useState<Record<string, any>>(
        fields.reduce((acc, field) => ({ ...acc, [field.name]: field.type === "checkbox" ? false : "" }), {})
    );
    const [formFields, setFormFields] = useState<Field[]>(fields);
    const [open, setOpen] = useState(false);
    const [newField, setNewField] = useState({ type: "text", label: "", options: [] });
    const [newOptions, setNewOptions] = useState<Option[]>([]);
    const [editFieldIndex, setEditFieldIndex] = useState<number | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleChange = (e: any) => {
        const { name, value, type } = e.target;
        const checked = e.target.checked;
        setFormData((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleAddField = () => {
        if (newField.label) {
            let fieldName = newField.label.toLowerCase().replace(/\s+/g, '_').slice(0, 10);
            let uniqueFieldName = fieldName;
            let counter = 1;
            while (formFields.some(field => field.name === uniqueFieldName)) {
                uniqueFieldName = `${fieldName}_${++counter}`;
            }
            const updatedFields = [...formFields, { ...newField, name: uniqueFieldName, options: newOptions } as Field];
            setFormFields(updatedFields);
            setFormData((prev) => ({ ...prev, [uniqueFieldName]: newField.type === "checkbox" ? false : "" }));
            setNewField({ type: "text", label: "", options: [] });
            setNewOptions([]);
            setOpen(false);
            if (onFieldsChange) onFieldsChange(updatedFields); // Call the callback if provided
        }
    };

    const handleRemoveField = (fieldName: string) => {
        const updatedFields = formFields.filter(field => field.name !== fieldName);
        setFormFields(updatedFields);
        if (onFieldsChange) onFieldsChange(updatedFields); // Call the callback if provided
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const reorderedFields = [...formFields];
        const [movedField] = reorderedFields.splice(result.source.index, 1);
        reorderedFields.splice(result.destination.index, 0, movedField);
        setFormFields(reorderedFields);
        if (onFieldsChange) onFieldsChange(reorderedFields); // Call the callback if provided
    };

    const handleEditField = (index: number) => {
        setEditFieldIndex(index);
        setNewField(formFields[index] as any);
        setNewOptions((formFields[index] as any).options || []);
        setOpen(true);
    };

    const handleSaveField = () => {
        if (newField.label && editFieldIndex !== null) {
            // Check for duplicate options
            const optionLabels = newOptions.map(option => option.label);
            const hasDuplicates = optionLabels.some((label, index) => optionLabels.indexOf(label) !== index);

            if (hasDuplicates) {
                setSnackbarOpen(true);
                return;
            }

            let fieldName = newField.label.toLowerCase().replace(/\s+/g, '_').slice(0, 10);
            let uniqueFieldName = fieldName;
            let counter = 1;
            while (formFields.some((field, index) => field.name === uniqueFieldName && index !== editFieldIndex)) {
                uniqueFieldName = `${fieldName}_${counter}`;
                counter++;
            }
            if (counter > 1) {
                uniqueFieldName = `${fieldName}_${counter - 1}`;
            }
            const updatedFields = [...formFields];
            updatedFields[editFieldIndex] = { ...newField, name: uniqueFieldName, options: newOptions } as Field;
            setFormFields(updatedFields);
            setFormData((prev) => ({ ...prev, [uniqueFieldName]: newField.type === "checkbox" ? false : "" }));
            setNewField({ type: "text", label: "", options: [] });
            setNewOptions([]);
            setOpen(false);
            setEditFieldIndex(null);
            if (onFieldsChange) onFieldsChange(updatedFields); // Call the callback if provided
        }
    };

    const handleAddOption = () => {
        if (newOptions.some(option => option.label === "" || option.value === "")) {
            return; // Prevent adding empty options
        }
        setNewOptions([...newOptions, { label: "", value: "" }]);
    };

    const handleOptionChange = (index: number, key: string, value: string) => {
        const updatedOptions = [...newOptions];
        updatedOptions[index] = { ...updatedOptions[index], [key]: value };

        setNewOptions(updatedOptions);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const renderField = (field: Field, index: number) => {
        const commonProps = {
            fullWidth: true,
            sx: { backgroundColor: theme.palette.background.paper, marginBottom: "8px" }
        };

        switch (field.type) {
            case "typography":
                return <Typography variant={field.variant || "body1"}>{field.label}</Typography>;
            case "radio":
                return (
                    <FormControl component="fieldset" fullWidth>
                        <Typography>{field.label}</Typography>
                        <RadioGroup row name={field.name} value={formData[field.name]} onChange={handleChange}>
                            {field.options?.map((option: Option) => (
                                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            case "checkbox":
                return (
                    <FormControlLabel
                        control={<Checkbox checked={formData[field.name]} onChange={handleChange} name={field.name} />}
                        label={field.label}
                    />
                );
            case "select":
                return (
                    <FormControl fullWidth>
                        <Typography>{field.label}</Typography>
                        <Select name={field.name} value={formData[field.name]} onChange={handleChange} {...commonProps}>
                            {field.options?.map((option: Option) => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case "textarea":
                return <TextField
                    multiline
                    label={field.label}
                    minRows={4}
                    name={field.name}
                    type={field.type || "text"}
                    value={formData[field.name]}
                    onChange={handleChange}
                    required={field.required || false}
                    {...commonProps}
                />
            default:
                return (
                    <TextField
                        label={field.label}
                        name={field.name}
                        type={field.type || "text"}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required || false}
                        {...commonProps}
                    />
                );
        }
    };

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 1, maxWidth: 600, margin: "auto", alignItems: "center", width: "100%" }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="formFields">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver, theme)}>
                                {formFields.map((field, index) => (
                                    <Draggable key={`${field.name}-${index}`} draggableId={`${field.name}-${index}`} index={index} isDragDisabled={!isEditMode}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, theme)}
                                            >
                                                {isEditMode && (
                                                    <Typography variant="body2" sx={{ cursor: "grab", marginBottom: "8px" }}>☰</Typography>
                                                )}
                                                {renderField(field, index)}
                                                {isEditMode && (
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
                                                        <IconButton onClick={() => handleRemoveField(field.name)} sx={{ color: "orange" }}>
                                                            <Delete />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleEditField(index)} color="primary">
                                                            <Edit />
                                                        </IconButton>
                                                    </Box>
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {isEditMode && (
                    <Fab color="primary" aria-label="add" onClick={() => setOpen(true)} sx={{ marginBottom: 2 }}>
                        <Add />
                    </Fab>
                )}
                <Button type="submit" variant="contained" color="primary" disabled={loggedIn}>
                    Submit
                </Button>
            </Box>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{editFieldIndex !== null ? "Edit Field" : "Add New Field"}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <Select
                            value={newField.type}
                            onChange={(e) => setNewField({ ...newField, type: e.target.value })}
                        >
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="textarea">TextArea</MenuItem>
                            <MenuItem value="radio">Radio</MenuItem>
                            <MenuItem value="checkbox">Checkbox</MenuItem>
                            <MenuItem value="select">Select</MenuItem>
                            <MenuItem value="typography">Typography</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Field Label"
                        value={newField.label}
                        onChange={(e) => setNewField({ ...newField, label: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    {newField.type === "radio" || newField.type === "select" ? (
                        <>
                            <Typography>Options</Typography>
                            {newOptions.map((option, index) => (
                                <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: "8px" }}>
                                    <TextField
                                        label="Option Label"
                                        value={option.label}
                                        onChange={(e) => handleOptionChange(index, "label", e.target.value)}
                                        fullWidth
                                    />
                                    <TextField
                                        label="Option Value"
                                        value={option.value}
                                        onChange={(e) => handleOptionChange(index, "value", e.target.value)}
                                        fullWidth
                                    />
                                    <IconButton onClick={() => setNewOptions(newOptions.filter((_, i) => i !== index))} sx={{ color: "orange" }}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button onClick={handleAddOption}>
                                Add Option
                            </Button>
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={editFieldIndex !== null ? handleSaveField : handleAddField} color="primary">
                        {editFieldIndex !== null ? "Save Field" : "Add Field"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Duplicate option detected"
                action={
                    <Button color="secondary" size="small" onClick={handleSnackbarClose}>
                        Close
                    </Button>
                }
            />
        </>
    );
};
