import React, { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { AccountCircle } from "@mui/icons-material";
import { AppBar, Toolbar, IconButton, TextField, Typography, Box, alpha, Menu, MenuItem, Select, FormControl, InputLabel, MenuItem as MuiMenuItem } from "@mui/material";
import { IconModal } from "./utils/IconModal";
import { useContext } from "../../utils/use-context";
import MenuIcon from '@mui/icons-material/Home';

export interface MenuAppBarProps {
  user: any;
  rootProps?: {
    title?: string;
    iconURL?: string;
    titleAlign?: 'left' | 'center' | 'right'; // titleAlign prop to control title alignment
  }
}

export const MenuAppBar = ({ rootProps = {}, user }: MenuAppBarProps) => {
  const { iconURL, title, titleAlign = 'center' } = rootProps; // Default to 'center'
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const chromosome = useContext(); // Replace this with the actual context hook
  const theme = useTheme(); // Use the theme hook

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutButtonClick = () => {
    chromosome.onLogoutButtonClick();
    handleClose();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    chromosome.onSearch(event.target.value);
  };

  const [newTitle, setNewTitle] = useState(title);
  const [newTitleAlign, setNewTitleAlign] = useState(titleAlign);

  useEffect(() => {
    if (title !== newTitle) {
      setNewTitle(title)
    }
  }, [title]);

  useEffect(() => {
    if (titleAlign !== newTitleAlign) {
      setNewTitleAlign(titleAlign)
    }
  }, [titleAlign]);

  const [iconModalOpen, setIconModalOpen] = useState(false)

  useEffect(() => {
    // Update favicon when iconURL changes
    if (iconURL) {
      // Find existing favicon link
      const faviconLink = document.querySelector("link[rel*='icon']") as HTMLLinkElement;

      if (faviconLink) {
        // Update existing favicon
        faviconLink.href = iconURL;
      } else {
        // Create new favicon link if none exists
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = iconURL;
        document.head.appendChild(newLink);
      }

      // Also update apple-touch-icon if it exists
      const touchIcon = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement;
      if (touchIcon) {
        touchIcon.href = iconURL;
      }
    }
  }, [iconURL]);

  const handleTitleAlignChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newAlign = event.target.value as 'left' | 'center' | 'right';
    setNewTitleAlign(newAlign);
    chromosome.onRootPropsChange({
      ...rootProps,
      titleAlign: newAlign // Update the titleAlign in rootProps
    });
  };

  return (
    <AppBar position="static" sx={{ boxShadow: 10 }}>
      <IconModal open={iconModalOpen} onClose={() => setIconModalOpen(false)} url={iconURL} onSave={(url) => chromosome.onRootPropsChange({
        ...rootProps,
        iconURL: url
      })} />
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        {
          user && <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              if (user) {
                setIconModalOpen(true);
              } else {
                chromosome.onPathSelect('/')
              }
            }}
          >
            {iconURL && user ? (
              <img src={iconURL} alt="Website Icon" style={{ width: 24, height: 24 }} />
            ) : (
              <MenuIcon />
            )}
          </IconButton>
        }

        {/* Centered Box */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100%',
          }}
        >
          {
            user ? (
              <TextField
                margin="normal"
                fullWidth
                name="title"
                label="Title"
                type="text"
                value={newTitle}
                onChange={(event) => {
                  setNewTitle(event.target.value);
                }}
                onBlur={() => {
                  chromosome.onRootPropsChange({
                    ...rootProps,
                    title: newTitle || "",
                    titleAlign: newTitleAlign // Pass titleAlign along with title
                  });
                }}
                sx={{
                  maxWidth: '100%',
                }}
                InputProps={{
                  // Styling the InputBase directly
                  inputProps: {
                    style: {
                      textAlign: newTitleAlign, // Use newTitleAlign to set text alignment dynamically
                    }
                  }
                }}
              />
            ) : (
              <Typography
                sx={{
                  cursor: "pointer", 
                  minWidth: "fit-content", 
                  textAlign: newTitleAlign, // Apply the alignment to normal Typography as well
                  width: '100%', // Ensure it fills the available space
                }}
                variant="h6"
                component="div"
                onClick={() => chromosome.onPathSelect('/')}
              >
                {title}
              </Typography>
            )
          }
        </Box>

        {/* Alignment Dropdown */}
        {user && (
          <FormControl>
            <InputLabel>Align</InputLabel>
            <Select
              value={newTitleAlign}
              onChange={handleTitleAlignChange as any}
              label="Align"
            >
              <MuiMenuItem value="left">Left</MuiMenuItem>
              <MuiMenuItem value="center">Center</MuiMenuItem>
              <MuiMenuItem value="right">Right</MuiMenuItem>
            </Select>
          </FormControl>
        )}

        {/* Right side components like IconButton */}
        {user && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Preferences</MenuItem>
              <MenuItem onClick={handleClose}>My Account</MenuItem>
              <MenuItem onClick={onLogoutButtonClick}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};
