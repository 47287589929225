import * as cessnalib from "cessnalib";
import { Exception, Particle } from "../../particle";
import { Nucleus, Vacuole, ListOptions } from "../vacuole";

export class VacuoleJs extends Vacuole {
    constructor(private particles: Particle[], nucleus: Nucleus) {
        super(nucleus);
    }
    getAlive(): Promise<void | Exception> {
        throw new Error("Method not implemented.");
    }
    async read<T extends Particle>(query: Partial<T>, count: number | "All" = 1): Promise<T[]> {
        const retVal: T[] = [];
        for (let i = 0, len = 0; i < this.particles.length && (count === "All" || len < count); i++) {
            if (cessnalib.js.Class.doesMongoCover(this.particles[i], query)) {
                retVal.push(this.particles[i] as T);
                len++;
            }
        }
        return retVal;
    }
    async save<T extends Particle>(particle: T, query: Partial<T> = {}, count: number | "All" = 1): Promise<void | Exception> {
        if (query) {
            let overrideCount = 0;
            for (let i = 0; i < this.particles.length && (count === "All" || overrideCount < count); i++) {
                if (cessnalib.js.Class.doesMongoCover(this.particles[i], query)) {
                    this.particles[i] = particle;
                    overrideCount++;
                }
            }
            if (overrideCount === 0) {
                this.particles.push(particle);
            }
        } else {
            this.particles.push(particle);
        }
    }
    async saveAll<T extends Particle>(particles: T[]): Promise<void | Exception> {
        this.particles.concat(particles);
    }
    async remove<T extends Particle>(query: Partial<T>, count: number | "All" = 1): Promise<void | Exception> {
        if (query) {
            let removeCount = 0;
            for (let i = 0; i < this.particles.length && (count === "All" || removeCount < count); i++) {
                if (cessnalib.js.Class.doesMongoCover(this.particles[i], query)) {
                    this.particles.splice(i, 1);
                    removeCount++;
                }
            }
        }
    }
    async list<T extends Particle>(query: Partial<T>, options?: ListOptions): Promise<T[] | Exception> {
        const offset = Number(options?.offset || 0);
        const limit = Number(options?.limit || 100);
        const order = options?.order || "desc";
        const orderBy = options?.orderBy || "_createdAt";

        let filteredParticles = this.particles.filter(particle =>
            cessnalib.js.Class.doesMongoCover(particle, query)
        );

        filteredParticles.sort((a, b) => {
            if (order === "asc") {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return a[orderBy] < b[orderBy] ? 1 : -1;
            }
        });

        return filteredParticles.slice(offset, offset + limit) as T[];
    }
}
